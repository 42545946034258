<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>휴면계정해제완료</h2>
				<a href="54.sub_작가페이지_성우리스트둘러보기(상세).html"><i class="bx bx-x"></i></a>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>
					회원님의 휴면 상태가 해제되었습니다.<br />
					안전한 서비스 이용을 위해 비밀번호를 변경해 주세요.
				</p>
			</div>
			<div class="btn_wrap_line">
				<button @click="store.dormancy_clear_modal = false" type="button" class="btn_basic btn_green_bg">
					확인
				</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
const store = userInfoStore();
</script>
