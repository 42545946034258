<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>아이디 찾기</h2>
				<i @click="store.find_id_modal = false" class="bx bx-x"></i>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>회원 가입 당시 등록한 추가 이메일 주소를 입력해 주세요.</p>
			</div>
			<div class="input_btn_wrap">
				<div class="item_id w_100">
					<input type="text" id="id" v-model="find_email" placeholder="이메일 주소를 입력해 주세요." />
				</div>
			</div>
			<p id="find_temp"></p>
			<div class="btn_wrap_line">
				<button type="button" class="btn_basic btn_grey_bg" @click="store.find_id_modal = false">취소</button>
				<button @click="find_id()" type="button" class="btn_basic btn_green_bg">확인</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
import { EMAIL_REGI } from '../Register';
import ajax from '@/api/api-manager';
import { ref } from 'vue';
const store = userInfoStore();
const find_email = ref(null);

//아이디찾기로직
const find_id = () => {
	const find_temp = document.querySelector('#find_temp');
	const email_ck = EMAIL_REGI.test(find_email.value);
	if (find_email.value !== null && find_email.value !== '' && email_ck) {
		ajax('user/find_id', find_email.value)
			.then((response) => {
				store.find_id_list = response.data.data;
				store.find_id_modal = false;
				store.find_id_clear_modal = true;
				alert('아이디 찾기가 완료되었습니다!');
			})
			.catch((error) => {
				if (error.response.data.code === 'M000') {
					find_temp.innerHTML = '등록된 아이디가 없습니다 !';
				}
			});
	} else {
		find_temp.innerHTML = '이메일을 확인하여주세요';
	}
};
</script>
<style scope>
#find_temp {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>
