<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>비밀번호 변경</h2>
				<i @click="store.find_pw_clear_modal = false" class="bx bx-x"></i>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>
					비밀번호 재설정 링크가 발송되었습니다.<br />
					메일함을 확인해 주세요.
				</p>
			</div>
			<div class="btn_wrap_line">
				<button @click="store.find_pw_clear_modal = false" type="button" class="btn_basic btn_green_bg">
					확인
				</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
const store = userInfoStore();
</script>
