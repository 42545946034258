<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>아이디 찾기 완료</h2>
				<a href=""><i class="bx bx-x"></i></a>
			</div>
			<div v-for="(p, index) in store.find_id_list" :key="index" class="mypage_tit_wrap border_b_none">
				<p>
					요청하신 회원님의 아이디(이메일)는<br />
					<span>{{ p }}</span> 입니다.
				</p>
			</div>
			<div class="btn_wrap_line">
				<button @click="store.find_id_clear_modal = false" type="button" class="btn_basic btn_green_bg">
					확인
				</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { userInfoStore } from '@/store/UserStore';
const store = userInfoStore();
</script>
