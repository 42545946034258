<template>
	<div>
		<div class="login_members_wrap">
			<div class="login_members">
				<div class="login_header_wrap">
					<h2>로그인</h2>
					<p>서비스 이용을 위해 로그인을 해주세요.</p>
				</div>
				<form @keyup="enterkey()" id="sign_form">
					<div class="input_wrap">
						<div class="item_id">
							<input
								v-model="login_email"
								name="id"
								type="text"
								id="id"
								placeholder="아이디를 입력해 주세요."
							/>
						</div>
						<div class="item_pw">
							<input name="password" type="password" id="pw" placeholder="비밀번호를 입력해 주세요." />
						</div>
					</div>
				</form>
				<div class="etc_wrap">
					<input type="checkbox" v-model="id_save" class="g_check" id="g_check1" /><label for="g_check1"
						>아이디저장</label
					>
					<div class="etc_btn_wrap">
						<router-link to="/signup">
							<div class="join"><a href="">회원가입</a></div>
						</router-link>
						<div @click="store.find_id_modal = true" class="find_id cursor_pointer">아이디 찾기</div>
						<div @click="store.find_pw_modal = true" class="find_pw cursor_pointer">비밀번호 찾기</div>
					</div>
				</div>
				<p id="loginfailed"></p>
				<div class="btn_wrap">
					<button type="button" @click="signin()" class="btn_basic_100 btn_green_bg">로그인</button>
				</div>
				<div class="sns_login_wrap">
					<ul>
						<li>
							<div class="cursor_pointer" id="naver_id_login"></div>
						</li>
						<li>
							<div class="cursor_pointer" id="kakao-login-btn" @click="loginWithKakao1()">
								<img src="@/assets/images/logo_kakao.jpg" alt="카카오 로그인 버튼" />
							</div>
						</li>
					</ul>
				</div>
				<div class="foot_servicecenter">
					<div class="call">
						<a href="tel:070-8825-5004">고객센터 : <span>070-8825-5004</span></a>
					</div>
					<div class="email">
						<a href="">이메일 : <span>contact@ideaconcert.com</span></a>
					</div>
				</div>
			</div>
		</div>
		<!-- 컴포넌트를 pinia에서 상태값을 설정해 주어서 전역으로 컴포넌트모달 관리가된다 -->
		<component :is="store.dormancy_modal ? Dormancy : null" />
		<component :is="store.dormancy_clear_modal ? DormancyClear : null" />
		<component :is="store.find_id_modal ? FindID : null" />
		<component :is="store.find_id_clear_modal ? FindIdClear : null" />
		<component :is="store.find_pw_modal ? FindPw : null" />
		<component :is="store.find_pw_clear_modal ? FindPwClear : null" />
	</div>
</template>

<script setup>
import ajax from '@/api/api-manager';
import { userInfoStore } from '@/store/UserStore';
import { useRouter } from 'vue-router';
import Dormancy from './modal/Dormancy.vue';
import DormancyClear from './modal/DormancyClear.vue';
import FindID from './modal/FindId.vue';
import FindIdClear from './modal/FindIdClear.vue';
import FindPwClear from './modal/FindPwClear.vue';
import FindPw from './modal/FindPw.vue';
import jwt from '@/api/LocalStorage';
import { ref, watch, onMounted } from 'vue';
import { contentPlayerStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';

const store = userInfoStore();
const router = useRouter();
const id_save = ref(null);
const login_email = ref('');
const ID_TOKEN_KEY = ref('id_token');
const token = ref(null);

// playerInfo store setting
const playerStore = contentPlayerStore();
const { playerVisible, playState } = storeToRefs(playerStore);
playerVisible.value = false;
playState.value = false;

//카카오로그인
const loginWithKakao1 = () => {
	const snsform = new FormData();
	//login 에서 loginForm 으로 변경시 로그인 할때 마다 id,pw 입력진행
	//로그인 후 사이트주소창에 로그인을 치고 화면 갔을 시 메인으로 되돌리거나 로그인이 이미되있다는 문구 필요
	//그 외에는 로그아웃전까지 로그인 버튼을 볼 수 없음
	window.Kakao.Auth.loginForm({
		//사용자 동의항목 지정
		scope: 'account_email,profile_nickname',
		success: function (res) {
			//로그인성공시 엑세스토큰 발급
			token.value = res.access_token;
			window.Kakao.Auth.setAccessToken(res.access_token);
			window.Kakao.API.request({
				//발급받은 액세스토큰으로 사용자 정보 요구
				url: '/v2/user/me',
				success: function (response) {
					//사용자 정보 조회 성공시 이메일 넘겨서 로그인 토큰 발급받는 API
					snsform.append('id', response.kakao_account.email);
					snsform.append('userType', 'KO');
					ajax('user/kakao_login', snsform).then((response) => {
						jwt.saveToken('id', response.data.data.id);
						jwt.saveToken(ID_TOKEN_KEY.value, response.data.data.token);
						jwt.saveToken('userNo', response.data.data.userNo);
						jwt.saveToken('nickname', response.data.data.nickname);
						jwt.saveToken('loginType', 'kakao');
						// jwt.saveToken('membership', response.data.data.membershipYn);
						// router.replace('/');
						window.location.href = '/';
						store.user_info();
					});
				},
			});
		},
	});
};

//로컬에 save_id로 저장값이 있으면 checked를 true
onMounted(() => {
	if (jwt.getToken('save_id')) {
		id_save.value = true;
	}
	//아이디가 있고 트루일때 이메일입력란 value값에 아이디 설정
	if (id_save.value === true) {
		login_email.value = window.localStorage.getItem('save_id');
	}
	naver_login();
	back_main();
});

const back_main = () => {
	// if (jwt.getToken('id')) {
	if (jwt.getToken(ID_TOKEN_KEY)) {
		alert('이미 로그인이 되어있습니다, 다른 아이디로 로그인을 원할 시 로그아웃 해주세요!');
		router.replace('/');
	}
};

const naver_login = () => {
	// const naver_id_login = new window.naver_id_login('TEA0Alw6wlCncJct7Oda', 'http://localhost:3000/login/naver');
	const naver_id_login = new window.naver_id_login('TEA0Alw6wlCncJct7Oda', 'https://www.toonflex.co.kr/login/naver');
	const state = naver_id_login.getUniqState();
	naver_id_login.setButton('green', 1, 40); // 버튼 설정
	naver_id_login.setState(state);
	naver_id_login.setPopup(); // popup 설정을 위한 코드
	naver_id_login.init_naver_id_login();
};
//input에 checked값 변경시 false일 경우 로컬스토리지에서 저장한 아이디 제거
watch(
	() => id_save.value,
	() => {
		if (id_save.value === false) {
			jwt.destroyToken('save_id');
			login_email.value = '';
		}
	},
);

const enterkey = () => {
	if (window.event.keyCode == 13) {
		// 엔터키가 눌렸을 때 실행할 내용
		signin();
	}
};

//로그인 로직
const signin = () => {
	const sign_form = document.getElementById('sign_form');
	const sign_temp = document.querySelector('#loginfailed');
	const form = new FormData(sign_form);
	ajax('user/signin', form)
		.then((response) => {
			sign_temp.innerHTML = '';
			const token = response.data.data.token;
			console.log(response.data.data);
			//로그인 성공 시 토큰,아이디,닉네임 정보 로컬에 저장
			jwt.saveToken('id', response.data.data.id);
			jwt.saveToken('nickname', response.data.data.nickname);
			jwt.saveToken('userNo', response.data.data.userNo);
			jwt.saveToken(ID_TOKEN_KEY.value, token);
			jwt.saveToken('loginType', 'normal');
			// jwt.saveToken('membership', response.data.data.membershipYn);
			// router.replace('/');
			window.location.href = '/';
			//checked가 true 일때 로컬에 아이디 저장
			if (id_save.value) {
				jwt.saveToken('save_id', login_email.value);
			}
			ajax('user/advice_pw')
				//로그인 성공 시 비밀번호 기한 만료일때 메인창으로 이동후 모달열어주기
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					if (error.response.data.code === 'M006') {
						store.advice_pw_modal = true;
					}
				});
		})
		.catch((error) => {
			//에러코드마다 알맞은 text 넣어주기
			if (error.response.data.code === 'M009') {
				sign_temp.innerHTML = '아이디를 다시 확인해 주세요.';
			} else if (error.response.data.code === 'M003') {
				sign_temp.innerHTML = '비밀번호를 다시 확인해 주세요.';
			} else if (error.response.data.code === 'M006') {
				sign_temp.innerHTML = '휴면 계정 입니다 !';
				store.dormancy_modal = true;
			}
		});
};
</script>
<style scope>
#loginfailed {
	font-size: 13px;
	color: #ef2828;
	margin-bottom: 20px;
	letter-spacing: -1px;
}
</style>
