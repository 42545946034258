<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>휴면계정해제</h2>
				<i class="bx bx-x" @click="store.dormancy_modal = false"></i>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>회원님의 계정은 현재 휴면 상태입니다. 원활한 서비스 이용을 위해 휴면 상태를 해제해 주세요.</p>
			</div>
			<div class="input_btn_wrap">
				<div class="item_id">
					<input v-model="dormancy_email" type="text" id="id" placeholder="이메일 주소를 입력해 주세요." />
				</div>
				<div class="btn">
					<button @click="send()" class="dormancy_send_btn" value="전송" type="button">
						{{ send_btn_text }}
					</button>
				</div>
			</div>
			<p id="dormancy_temp_email"></p>
			<div class="input_btn_wrap">
				<div class="item_id">
					<input class="dormancy_code" type="text" id="id" value="" placeholder="인증번호를 입력해 주세요." />
					<span class="time">{{ real_time }}</span>
				</div>
				<div class="btn">
					<button
						id="dormancy_cf"
						type="button"
						v-if="!code_btn_text"
						@click="certification()"
						class="btn_green_bg"
					>
						인증
					</button>
					<button id="dormancy_cf" type="button" v-else @click="certification()" class="btn_green_bg">
						완료
					</button>
				</div>
			</div>
			<div class="explanation">
				인증번호가 발송되지 않았거나 이메일을 잘못입력한 후 발송했을 경우
				<span class="red"><a @click="time_reset(), re_send()">재전송</a></span
				>을 클릭해 주세요.
			</div>
			<div class="btn_wrap_line">
				<button type="button" @click="store.dormancy_modal = false" class="btn_basic btn_grey_bg">취소</button>
				<button @click="release_apply()" type="button" class="btn_basic btn_darkgreen_bg">신청하기</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { EMAIL_REGI } from '../Register';
import { userInfoStore } from '@/store/UserStore';
import moment from 'moment';
import ajax from '@/api/api-manager';
import { ref, watch } from 'vue';

const real_time = ref('03:00');
const dormancy_email = ref(null);
const code_ck = ref(null);
const server_cf_code = ref(null);
const sendemail = ref(false);
const send_btn_text = ref('전송');
const time = ref(180);
const authorized = ref(false);
const code_btn_text = ref(false);
const store = userInfoStore();
//메일인증코드 전송완료시 시간이 줄어들게 만듬
watch(
	() => time.value,
	() => {
		if (time.value === 0) {
			real_time.value = '00:00';
		} else if (time.value > 0) {
			real_time.value = moment(time.value * 1000).format('mm:ss');
		}
	},
);
//메일인증코드 전송중 or 완료시 버튼 텍스트 변경 및 disabled 값 주기
watch(
	() => code_ck.value,
	() => {
		const code_sending = document.querySelector('.dormancy_send_btn');
		if (code_ck.value === false) {
			code_sending.disabled = true;
		}
		if (code_ck.value === false) {
			send_btn_text.value = '전송중';
		} else if (code_ck.value === true) {
			send_btn_text.value = '완료';
		}
	},
);
const time_reset = () => {
	time.value = 180;
};

const send = () => {
	//유효성 검사
	const email_ck = EMAIL_REGI.test(dormancy_email.value);
	const dormancy_temp_email = document.querySelector('#dormancy_temp_email');
	// 이메일공백 or 조합 유효성검사
	if (dormancy_email.value !== '' && email_ck === true) {
		code_ck.value = false;
		ajax('user/dormancy_code', dormancy_email.value).then((res) => {
			code_ck.value = true;
			window.alert('전송이 완료되었습니다.');
			server_cf_code.value = res.data.data;
			//발송후 3:00 초 시간 줄어들기
			setInterval(() => {
				time.value--;
			}, 1000);
		});
		sendemail.value = true;
		dormancy_temp_email.innerHTML = '';
	} else {
		dormancy_temp_email.innerHTML = '올바른 이메일 형식이 아닙니다.';
	}
};
const re_send = () => {
	//유효성 검사
	const email_ck = EMAIL_REGI.test(dormancy_email.value);
	const dormancy_temp_email = document.querySelector('#dormancy_temp_email');
	const cf_code = document.querySelector('.dormancy_code');
	const certify_btn = document.querySelector('#dormancy_cf');
	// 이메일공백 or 조합 유효성검사
	if (dormancy_email.value !== '' && email_ck === true) {
		code_ck.value = false;

		ajax('user/dormancy_code', dormancy_email.value).then((res) => {
			code_ck.value = true;
			window.alert('전송이 완료되었습니다.');
			server_cf_code.value = res.data.data;
			certify_btn.disabled = false;
			cf_code.disabled = false;
		});
		sendemail.value = true;
		dormancy_temp_email.innerHTML = '';
	} else {
		dormancy_temp_email.innerHTML = '올바른 이메일 형식이 아닙니다.';
	}
};

const certification = () => {
	const cf_code = document.querySelector('.dormancy_code');
	const certify_btn = document.querySelector('#dormancy_cf');
	if (cf_code.value !== '') {
		if (cf_code.value === server_cf_code.value) {
			//인증완료시 인풋 and 버튼 disabled 및 인증시간 0 만들기
			alert('인증이 완료되었습니다.');
			code_btn_text.value = true;
			time.value = 0;
			setTimeout(() => {
				certify_btn.disabled = true;
			}, 100);

			cf_code.disabled = true;
			authorized.value = true;
		} else {
			window.alert('인증코드를 확인해주세요');
		}
	} else {
		window.alert('인증코드를 확인해주세요');
	}
};
//휴면계정해제 신청
const release_apply = () => {
	const form = new FormData();
	form.append('id', dormancy_email.value);
	if (authorized.value) {
		ajax('user/dormancy_release', form).then((res) => {
			store.dormancy_modal = false;
			store.dormancy_clear_modal = true;
		});
	}
};
</script>
<style scope>
#dormancy_temp_email {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>
